import './app.css'
import React, { createContext, useReducer, useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Router } from './app/router'
import { ThemeProvider } from '@/components/themes/default'
import { useForm, FormProvider } from 'react-hook-form'
import { getPersistedState, persistState } from '@/store'
import { SWRConfig } from 'swr'
import { fetcher } from '@/submodules/swr-fetch'

const initialState = {
  chat: {
    open: false,
  },
  session: {
    isAdmin: false,
    isAuthenticated: false,
  },
  selectedYear: (() => {
    const now = new Date()
    const currentYear = now.getFullYear()
    const currentMonth = now.getMonth() + 1
    return currentMonth >= 5 ? currentYear + 1 : currentYear
  })(),
  selectedDivision: '',
}

const initialValue: {
  state: any
  dispatch: React.Dispatch<any>
} = {
  state: getPersistedState() ?? initialState,
  dispatch: () => {},
}

export const AppContext = createContext(initialValue)

const AppContextProvider: React.FC = (props) => {
  const [state, dispatch] = useReducer((state: any, action: any) => {
    switch (action.type) {
      case 'SET_APP_HEADER_TITLE':
        window.document.title = `${action.payload.text} | じょぶる`
        return {
          ...state,
          appHeaderTitle: action.payload,
        }

      case 'TOGGLE_CHAT_OPEN':
        return {
          ...state,
          chat: {
            ...state.chat,
            open: !state.chat.open,
          },
        }

      case 'OPEN_CHAT':
        return {
          ...state,
          chat: {
            ...state.chat,
            open: true,
            studentId: action.payload.studentId,
            chatId: action.payload.chatId,
            name: action.payload.name,
          },
        }

      case 'ADD_TO_BULK_CHAT':
        return {
          ...state,
          chat: {
            ...state.chat,
            open: true,
            client: undefined,
            // Add unique students to the array, based on unique student id
            bulkChatStudents: [...(state.chat.bulkChatStudents ?? []), ...action.payload.students].filter(
              (v, i, a) => a.map((a) => a.id).indexOf(v.id) === i,
            ),
          },
        }

      case 'REMOVE_FROM_BULK_CHAT':
        return {
          ...state,
          chat: {
            ...state.chat,
            // Remove from array given payload.id
            bulkChatStudents: state.chat.bulkChatStudents.filter((s) => s.id !== action.payload.id),
          },
        }

      case 'REMOVE_ALL_BULK_CHAT':
        return {
          ...state,
          chat: {
            ...state.chat,
            bulkChatStudents: [],
          },
        }

      case 'SHOW_CLIENT_CHAT':
        return {
          ...state,
          chat: {
            ...state.chat,
            open: true,
            client: action.payload.client,
            bulkChatStudents: [],
          },
        }

      case 'HIDE_CLIENT_CHAT':
        return {
          ...state,
          chat: {
            ...state.chat,
            client: undefined,
          },
        }

      case 'SET_IS_ADMIN':
        return {
          ...state,
          session: {
            ...state.session,
            isAdmin: action.payload.isAdmin,
          },
        }

      case 'SET_ICON_URL':
        return {
          ...state,
          session: {
            ...state.session,
            iconUrl: action.payload.iconUrl,
          },
        }

      case 'LOGIN':
        return {
          ...initialState,
          session: {
            ...action.payload,
          },
        }

      case 'LOGOUT':
        return {
          ...initialState,
          session: {
            isAuthenticated: false,
          },
        }

      case 'UPDATE_SESSION':
        return {
          ...state,
          session: {
            ...state.session,
            ...action.payload,
          },
        }

      case 'UPDATE_YEAR':
        const selected = action.payload.split('-')
        const selectedType = selected[0]
        const selectedValue = selected[1]
        const year = selectedType === 'year' ? selectedValue : ''
        const division = selectedType === 'division' ? selectedValue : ''

        return {
          ...state,
          selectedYear: year,
          selectedDivision: division,
        }

      default:
        throw new Error(`Unknown action type: ${action.type}`)
    }
  }, initialValue.state)

  useEffect(() => persistState(state), [state])

  return <AppContext.Provider value={{ state, dispatch }}>{props.children}</AppContext.Provider>
}

export const App: React.FC = () => {
  const methods = useForm({
    mode: 'onChange',
  })

  return (
    <div className="App">
      <SWRConfig value={{ fetcher }}>
        <AppContextProvider>
          <FormProvider {...methods}>
            <ThemeProvider>
              <BrowserRouter>
                <Router />
              </BrowserRouter>
            </ThemeProvider>
          </FormProvider>
        </AppContextProvider>
      </SWRConfig>
    </div>
  )
}
