import { parseISO } from 'date-fns'

const getCutoffDate = () => {
  const now = new Date()
  const currentYear = now.getFullYear()
  const currentMonth = now.getMonth() + 1

  const cutoffYear = currentMonth >= 5 ? currentYear : currentYear - 1
  return new Date(cutoffYear, 2, 31) // March 31st
}

export const filterJobseekers = (jobseekers: any[]) => {
  const cutoffDate = getCutoffDate()
  return jobseekers.filter((jobseeker) => {
    if (!jobseeker.graduatedAt || jobseeker.graduatedAt === '0001-01-01T00:00:00Z') {
      return true
    }
    const gradDate = parseISO(jobseeker.graduatedAt)
    return gradDate > cutoffDate
  })
}
